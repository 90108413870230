import React, { useState, useEffect } from "react";
import "../../../Styles/StoreSetting.css";
import "../../../Styles/Settings/SystemAccess.css";
import "../../../Styles/Common.css";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  clearPermission,
  fetchEmployeeData,
  fetchPermissionData,
} from "../../../Redux/features/StoreSettings/AddEmployee/AddEmployeeSlice";
import EditPermissionLogic from "../AddEmployee/EditPermissionLogic";
import { useAuthDetails } from "../../../Common/cookiesHelper";
import AddNewCategory from "../../../Assests/Taxes/Left.svg";
import Loader from "../../../CommonComponents/Loader";
import {
  Collapse,
  Button,
  FormControlLabel,
  Switch,
  Grid,
  CircularProgress,
} from "@mui/material";
const Permission = ({ EmployeeId, setVisible }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [employeedata, setemployeedata] = useState([]);

  const employee_id = EmployeeId;
  const employeeListDataState = useSelector((state) => state.employeelistData);

  const [permissionArray, setPermissionArray] = useState([]);

  const {
    handleEditEmpPermissionInput,
    handleToggleCategory,
    values,
    handleEditEmpPermission,
    submitmessage,
    showModal,
    setShowModal,
    scrollRef,
    setsubmitmessage,
    open,
    setOpen,
    setValues,
    setpermissionList,
    permissionList,
  } = EditPermissionLogic({ employeedata, setVisible });
  useEffect(() => {
    const hasErrors = Object.values(values.errors).some(
      (error) => error !== ""
    );
    // Determine whether to open based on specific values
    const shouldOpen = Boolean(
      (values.break_allowed && values.break_allowed !== "0") ||
        (values.break_time && values.break_time !== "0") ||
        (values.paid_breaks && values.paid_breaks !== "0")
    );
    !hasErrors && setOpen(shouldOpen);
  }, [values.break_allowed, values.break_time, values.paid_breaks]);
  console.log(
    "open",
    Boolean(
      (values.break_allowed && values.break_allowed === "0") ||
        (values.break_time && values.break_time === "0") ||
        (values.paid_breaks && values.paid_breaks === "0")
    )
  );
  const toggleVisibility = () => {
    setOpen((prev) => !prev);
    setValues((prev) => ({
      ...prev,
      errors: {
        ...prev.errors,
        break_allowed: "",
        break_time: "",
        paid_breaks: "",
      },
    }));
  };
  const { LoginGetDashBoardRecordJson, LoginAllStore, userTypeData } =
    useAuthDetails();
  let AuthDecryptDataDashBoardJSONFormat = LoginGetDashBoardRecordJson;
  const merchant_id = AuthDecryptDataDashBoardJSONFormat?.data?.merchant_id;

  useEffect(() => {
    let data = {
      merchant_id: merchant_id,
      employee_id: employee_id,
      ...userTypeData,
    };
    if (data && employee_id != "undefined") {
      dispatch(fetchEmployeeData(data));
      dispatch(fetchPermissionData(data));
    }
    return () => {
      dispatch(clearPermission());
    };
  }, []);

  useEffect(() => {
    if (
      !employeeListDataState.loading &&
      employeeListDataState.employeeData &&
      employeeListDataState.permissionData
    ) {
      setemployeedata(employeeListDataState.employeeData);
      setpermissionList(employeeListDataState.permissionData);
    }
  }, [
    employeeListDataState,
    employeeListDataState.loading,
    employeeListDataState.employeeData,
    employeeListDataState.permissionData,
  ]);

  const permissionList_arr = [];
  function MyComponent(permissionList) {
    let index = 0;

    for (const key in permissionList) {
      if (permissionList.hasOwnProperty(key)) {
        // Create an object with a single key-value pair and push it to the array
        const obj = {};
        obj[key] = permissionList[key];
        permissionList_arr[index] = obj;
        index++;
      }
    }
    setPermissionArray(permissionList_arr);

    return (
      <div className="box">
        <div>
          <h1>{JSON.stringify(permissionList_arr)} </h1>{" "}
          {JSON.stringify(permissionList_arr)} {/* Just for demonstration */}
        </div>
        \
      </div>
    );
  }

  useEffect(() => {
    MyComponent(permissionList);
  }, [permissionList, employeedata]);

  const RedirectCancelButton = () => {
    setVisible("EmployeeList");
    navigate(`/store-settings/addemployee`);
  };

  const permissionOrder = [
    "Home Screen", // 
    "Register", // 
    "Orders", // 
    "Gift Card", // 
    "Customers", // 
    "Station Settings", // 
    "More", // 
    "Reporting", // 
    "Inventory", // 
    "Purchase Orders", // 
    "Stocktake", // 
    "Vendor", // 
    "Employee", // 
    "Coupons", //
    "Mix N' Match" ,
    "Loyalty Program",
    "Taxes", // 
    "Store Settings", //  
  ];
  const headings = {
    "Home Screen": "POS",
    "Reporting": "Dashboard",
  };
  // Helper function to render heading based on key
  const renderHeading = (key) => {
    const title = headings[key];
    return title ? (
      <div className="text-[24px] px-2 sm:px5 mb-3">
        <h1>{title}</h1>
      </div>
    ) : null;
  };

  return (
    // fro laoder Start
    <div style={{ paddingBottom: "120px" }} className="box">
      {/* edit modal */}
      {employeeListDataState.loading ? (
        <div class="loading-box">
          <Loader />
        </div>
      ) : (
        <>
          <div className="q-attributes-main-page">
            <div
              className="box_shadow_div_headings"
              style={{ paddingBottom: "0px !important" }}
            >
              <div className="page_heading_area ">
                <span
                  onClick={() => {
                    setVisible("EmployeeList");
                  }}
                  className="cursor-pointer"
                  // to="/store-settings/addemployee"
                >
                  <div className="employeePromiss">
                    <img src={AddNewCategory} alt="Add-New-Category" />
                    <h1>
                      {employeedata.f_name} {employeedata.l_name}'s Permissions
                    </h1>
                  </div>
                </span>
              </div>
              <div className="box_shadow_innerdiv">
                <h2 className="heading_black">Preset Permissions</h2>
                <Grid container sx={{ mt: 0 }} spacing={2} className="">
                  <Grid item className="input_rediobutton_area">
                    <input
                      className="inputredio"
                      type="radio"
                      id="manager"
                      name="role"
                      value="manager"
                      onChange={handleEditEmpPermissionInput}
                      checked={
                        values.role === "manager" || values.role === null
                      }
                      // defaultChecked={values.role == 'manager' || values.role == 'null'}
                      // defaultChecked={values.role === 'manager'}
                    />
                    <label htmlFor="manager">Manager</label>
                  </Grid>
                  <Grid item className="input_rediobutton_area">
                    <input
                      className="inputredio"
                      type="radio"
                      id="cashier"
                      name="role"
                      value="cashier"
                      onChange={handleEditEmpPermissionInput}
                      checked={
                        values.role === "cashier" && values.role !== null
                      }
                      // defaultChecked={values.role !=='' && values.role === 'cashier'}
                    />
                    <label htmlFor="cashier">Cashier</label>
                  </Grid>
                  <Grid item className="input_rediobutton_area">
                    <input
                      className="inputredio"
                      type="radio"
                      id="driver"
                      name="role"
                      value="driver"
                      onChange={handleEditEmpPermissionInput}
                      checked={values.role === "driver" && values.role !== null}
                      // defaultChecked={values.role !=='' && values.role === 'driver'}
                    />
                    <label htmlFor="driver">Driver</label>
                  </Grid>
                  <Grid item className="input_rediobutton_area">
                    <input
                      className="inputredio"
                      type="radio"
                      id="time_clock_only"
                      name="role"
                      value="time_clock_only"
                      onChange={handleEditEmpPermissionInput}
                      checked={
                        values.role === "time_clock_only" &&
                        values.role !== null
                      }
                      // defaultChecked={values.role !=='' && values.role === 'time_clock_only'}
                    />
                    <label htmlFor="time_clock_only">Time Clock Only</label>
                  </Grid>
                </Grid>
                <span className="input-error">
                  {values.errors.role !== "" ? values.errors.role : ""}
                </span>
              </div>
            </div>

            <div className="">
              {/* <div className="page_heading_area">
                <h1>Permissions</h1>
              </div> */}

              {permissionArray
                .sort((a, b) => {
                  const keyA = Object.keys(a)[0];
                  const keyB = Object.keys(b)[0];
                  return (
                    permissionOrder.indexOf(keyA) -
                    permissionOrder.indexOf(keyB)
                  );
                })
                .map((item, index) => {
                  const key = Object.keys(item)[0]; // Gets the first key in the object
                  const categoryPermissions = item[key];
                  const idArray = values?.permissions?.split(",");

                  return (
                    <>
                     {renderHeading(key)}
                    <Grid sx={{marginTop:0}} key={key} className="box_shadow_div_heading">
                     

                      <div className="px-2 sm:px-5 pt-4">
                        <div className="flex items-center justify-between">
                          <div>
                            <h2 className="heading_black">{key}</h2>
                          </div>
                          <div className="">
                            <div
                              style={{ marginRight: "0px", marginTop: "0px" }}
                              className="qv_checkbox"
                            >
                              <label className="qv_checkbox_add_checkmark_label text-center">
                                Check All
                                <input
                                  type="checkbox"
                                  onChange={(e) =>
                                    handleToggleCategory(
                                      categoryPermissions,
                                      e.target.checked
                                    )
                                  }
                                  checked={categoryPermissions.every(
                                    (subItem) =>
                                      idArray.includes(
                                        String(subItem.permission_code)
                                      )
                                  )}
                                />
                                <span className="qv_add_checkmark"></span>
                              </label>
                            </div>
                          </div>
                        </div>

                        <Grid container sx={{ mt: 1 }}>
                          {item[key]
                            .slice()
                            .sort((a, b) =>
                              a?.alternateName?.localeCompare(b?.alternateName)
                            )
                            .map((subItem, subIndex) => (
                              <Grid
                                item
                                xs={6}
                                sm={4}
                                md={3}
                                key={subIndex}
                                className={` ${subItem.sub_permission}`}
                              >
                                <div className="checkbox_space">
                                  <div className="qv_checkbox">
                                    <label className="qv_checkbox_add_checkmark_label text-nowrap">
                                      {subItem.sub_permission}
                                      <input
                                        type="checkbox"
                                        name="permission[]"
                                        onChange={handleEditEmpPermissionInput}
                                        value={subItem.permission_code}
                                        // defaultChecked={idArray?.includes(
                                        //   String(subItem.id)
                                        // )}
                                        checked={idArray?.includes(
                                          String(subItem.permission_code)
                                        )}
                                      />
                                      <span className="qv_add_checkmark"></span>
                                    </label>
                                  </div>
                                </div>
                              </Grid>
                            ))}
                        </Grid>
                      </div>
                    </Grid>
                    </>
                    
                  );
                })}
            </div>

            <div className="box_shadow_div_heading">
              <Grid sx={{px:{xs:1,sm:2.5}}} className="box_shadow_innerdiv">
                <div className="flex items-center justify-between">
                  <h2 className="heading_black">Time Clock Settings</h2>
                  <FormControlLabel
                    sx={{ marginRight: "0px" }}
                    control={
                      <Switch
                        checked={open}
                        onChange={toggleVisibility}
                        color="primary"
                      />
                    }
                  />
                </div>

                <Collapse in={open}>
                  <div className="mt10">
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={4}>
                        <div className="input_area">
                          <label>Number of Breaks Allowed</label>
                          <input
                            type="text"
                            name="break_allowed"
                            onChange={(e) => {
                              if (e.target.value.length < 5) {
                                handleEditEmpPermissionInput(e);
                              }
                            }}
                            value={values.break_allowed}
                            placeholder="0"
                          />
                          <span className="input-error">
                            {values.errors.break_allowed !== ""
                              ? values.errors.break_allowed
                              : ""}
                          </span>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <div className="input_area">
                          <label>Minutes Per Break</label>
                          <input
                            type="text"
                            name="break_time"
                            onChange={(e) => {
                              if (e.target.value.length < 4) {
                                handleEditEmpPermissionInput(e);
                              }
                            }}
                            value={values.break_time}
                            placeholder="0"
                          />
                          <span className="input-error">
                            {values.errors.break_time !== ""
                              ? values.errors.break_time
                              : ""}
                          </span>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <div className="input_area">
                          <label>Number of Paid Breaks</label>
                          <input
                            type="text"
                            name="paid_breaks"
                            // onChange={handleEditEmpPermissionInput}
                            onChange={(e) => {
                              if (e.target.value.length < 5) {
                                handleEditEmpPermissionInput(e);
                              }
                            }}
                            value={values.paid_breaks}
                            placeholder="0"
                          />
                          <span className="input-error">
                            {values.errors.paid_breaks !== ""
                              ? values.errors.paid_breaks
                              : ""}
                          </span>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </Collapse>
              </Grid>
            </div>
          </div>
        </>
      )}
      <Grid className="fixed-bottom">
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          sx={{
            mb: 0,
            py:2.5,
            px: {xs:1,sm:2.5},
            width: "100%",
            height: "auto",
            boxShadow: "0 5px 10px 1px #ddd",
            // margin: "30px 0px",
            borderRadius: "8px",
            backgroundColor: "#fff",
            // padding: "0px",
            overflow: "hidden",
          }}
        >
          <div className=" md:mr-0">
            <div className="col-qv-12">
              <div
                style={{ padding: 0 }}
                className="q-add-categories-section-middle-footer"
              >
                <button
                  className="quic-btn quic-btn-save"
                  onClick={handleEditEmpPermission}
                >
                  Update
                </button>

                <Link
                  onClick={() => RedirectCancelButton()}
                  // to={`/store-settings/addemployee`}
                >
                  <button className="quic-btn quic-btn-cancle">Cancel</button>
                </Link>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>

    // fro laoder End
  );
};

export default Permission;
