import React from "react";
import { priceFormate } from "../hooks/priceFormate";
import { formatCurrency } from "../Constants/utils";
import NoDataFound from "../reuseableComponents/NoDataFound";
import Skeleton from "react-loading-skeleton";

export const CommonTable = ({
  isShow,
  childHeader,
  childrenBody,
  childFooter,
  loading
}) => {
  return (
    <div className="commonTable">
      <div className="table-container">
      <table>
        <thead class="table-header">
          <tr className="tableStickyHead">{isShow ? childHeader : null}</tr>
        </thead>

        {loading ?(
          <tbody class="table-body">
                {[1, 2, 3, 4,5,6].map((row) => (
                  <tr key={row}>
                    {childHeader.map((col) => (
                      <td key={col}>
                        <Skeleton />
                      </td>
                    ))}
                  </tr>
                ))}
            </tbody>
        ):(
          <>
          <tbody class="table-body">{isShow ? childrenBody : null}</tbody>
          {childrenBody?.length > 0 && ( <tfoot className="table-footer">{isShow ? childFooter : null}</tfoot>   )}
        </>
        )
      }
      </table>
      {!childrenBody?.length > 0 && !loading && (<NoDataFound table={true} />)}
    </div>
    </div>
    
  );
};

export default CommonTable;
