import React from "react";
import EmployeelistReport from "./EmployeelistReport";

const MainEmployeelist = ({ hide = false, setCSVData, setCSVHeader }) => {
  return (
    <>
      <div className="q-order-main-page">
        <EmployeelistReport
          hide={hide}
          setCSVData={setCSVData}
          setCSVHeader={setCSVHeader}
        />
      </div>
    </>
  );
};

export default MainEmployeelist;
