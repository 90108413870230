import React from 'react'

function SumaryMain() {
  return (
    <>
    Sale Summary
    </>
  )
}

export default SumaryMain