export const generateCSVDataAndHeaders = (productListData, headers) => {
    const formatDate = (dateString) => {
        const [day, month, year] = dateString.split('-');
    const date = new Date(`${year}-${month}-${day}`);
    
    return date.toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
    });
    };

    

    const formatFloat = (value) => (value ? parseFloat(value).toFixed(2) : "0.00");

    const CSVData = productListData.map((product) => {
        const dataRow = {};

        headers.forEach((header) => {
            const key = header.id;
            const keys = key.split(".");
            let value = product;
            // keys.forEach((k) => {
            //     value = value ? value[k] : undefined;
            // });

            
            keys.forEach((k) => {
                if (k === "title") {
                   
                    value = value 
                        ? `${value[k]} \n ${value.variant || ''} \n ${value.created_at || ''}`
                        : undefined;
                } else {
                    value = value ? value[k] : undefined;
                }
            });
            if (key.includes("value")) {
                dataRow[key] = formatFloat(value);
            } else if (key.includes("qty")) {
                dataRow[key] = value || 0;
            } else if (["created_on", "Date", "lastSale",'created_on'].includes(key)) {
                dataRow[key] = value ? formatDate(value) : "";
            } else {
                dataRow[key] = value || "";
            }
        });

        return dataRow;
    });

    const CSVHeaders = headers.map((column) => ({
        label: column.name,
        key: column.id,
    }));

    return { CSVData, CSVHeaders };
};
