import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  BASE_URL,
  DISCOUNT_SALES_TABLE_LIST,
  TOP_DISCOUNTS_BY_AMOUNT,
  TOP_SALE_DISCOUNT_LIST,
} from "../../../../Constants/Config";

const initialState = {
  loading: false,
  discountSalesData: {},
  topDiscounts: {},
  topDiscountsByAmount: {},
  status: false,
  successMessage: "",
  error: "",
};

export const fetchDiscountSales = createAsyncThunk(
  "discountSalesReportSlice/fetchDiscountSales",
  async (data, { rejectWithValue }) => {
    try {
      const { token, ...dataNew } = data;
      const res = await axios.post(
        BASE_URL + DISCOUNT_SALES_TABLE_LIST,
        dataNew,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      //   console.log("discount sales: ", res);
      if (res.data.status === true) {
        // console.log(response.data)

        const data = res.data.discount_data;
        const status = res.data.status;
        return { data, status };
      } else if (res.data.status === false) {
        // console.log("inside else if");
        const data = null;
        const status = false;
        return { data, status };
      }
    } catch (error) {
      const customError = {
        message: error.message,
        status: error.response ? error.response.status : "Network Error",
        data: error.response ? error.response.data : null,
      };
      return rejectWithValue(customError);
    }
  }
);

export const fetchTopDiscounts = createAsyncThunk(
  "discountSalesReportSlice/fetchTopDiscounts",
  async (data, { rejectWithValue }) => {
    try {
      const { token, ...dataNew } = data;
      const res = await axios.post(BASE_URL + TOP_SALE_DISCOUNT_LIST, dataNew, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
      //   console.log("top discounts list: ", res);
      if (res.data.status === true) {
        const data = res.data.discount_data;
        // console.log("api data: ", data);
        return { data };
      } else if (res.data.status === false) {
        return {};
      }
    } catch (error) {
      const customError = {
        message: error.message,
        status: error.response ? error.response.status : "Network Error",
        data: error.response ? error.response.data : null,
      };
      return rejectWithValue(customError);
    }
  }
);

export const fetchTopDiscountsByAmount = createAsyncThunk(
  "discountSalesReportSlice/fetchTopDiscountsByAmount",
  async (data, { rejectWithValue }) => {
    try {
      const { token, ...dataNew } = data;
      const res = await axios.post(
        BASE_URL + TOP_DISCOUNTS_BY_AMOUNT,
        dataNew,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("top discounts by amount: ", res);
      if (res.data.status === true) {
        const data = res.data.discount_data;
        // console.log("api data: ", data);
        return { data };
      } else if (res.data.status === false) {
        return {};
      }
    } catch (error) {
      const customError = {
        message: error.message,
        status: error.response ? error.response.status : "Network Error",
        data: error.response ? error.response.data : null,
      };
      return rejectWithValue(customError);
    }
  }
);

const discountSalesReportSlice = createSlice({
  name: "discountSalesReportSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchDiscountSales.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchDiscountSales.fulfilled, (state, action) => {
      state.loading = false;
      state.discountSalesData = action.payload.data;
      state.status = action.payload.status;
      state.error = "";
    });
    builder.addCase(fetchDiscountSales.rejected, (state, action) => {
      state.loading = false;
      state.discountSalesData = {};
      state.error = action.error.message;
    });

    builder.addCase(fetchTopDiscounts.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchTopDiscounts.fulfilled, (state, action) => {
      state.loading = false;
      state.topDiscounts = action.payload.data;
      state.status = action.payload.status;
      state.error = "";
    });
    builder.addCase(fetchTopDiscounts.rejected, (state, action) => {
      state.loading = false;
      state.topDiscounts = {};
      state.error = action.error.message;
    });

    builder.addCase(fetchTopDiscountsByAmount.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchTopDiscountsByAmount.fulfilled, (state, action) => {
      state.loading = false;
      state.topDiscountsByAmount = action.payload.data;
      state.status = action.payload.status;
      state.error = "";
    });
    builder.addCase(fetchTopDiscountsByAmount.rejected, (state, action) => {
      state.loading = false;
      state.topDiscountsByAmount = {};
      state.error = action.error.message;
    });
  },
});
export default discountSalesReportSlice.reducer;
