import React from 'react'
import { useAuthDetails } from '../../Common/cookiesHelper';
import { useSelector, useDispatch } from "react-redux";

const CateDetailsDescription = () => {
  const {
    LoginGetDashBoardRecordJson,
    userTypeData,
    inventory_approval
  } = useAuthDetails();
  return (
    <>
    <div className='box'>
        <div className=''>
        <div className='q-category-top-detail-section'>
                    <li>In order to use the Quickvee app one Category is required.</li>
                    {userTypeData?.login_type !== "superadmin" &&
                    inventory_approval === "1" ? (
                      <>
                    <li>If you make changes to the Category, the Category status will be pending until the admin approves it.</li>
                    <li>After you've made changes to your menu, select the option "Click Here To Send For Approval To Admin" to get admin approval to update your website.</li>
                      </>
                  ) : (
                    ""
                  )}
                </div>
        </div>
    </div>
    
    
    
    </>
  )
}

export default CateDetailsDescription