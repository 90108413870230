import { useState, useEffect } from "react";

const useMultiSelect = (
  options,
  userTypeData,
  merchantData,
  dateRange,
  fetchData
) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [allOptionSelected, setAllOptionSelected] = useState(false);

  const handleOptionClick = (selectedItem) => {
    if (selectedItem === "All") {
      if (selectedItems.length === options.length) {
        // Deselect all
        setSelectedItems([]);
        setAllOptionSelected(false);
      } else {
        // Select all
        const selectAll = options.map((item) => ({ ...item }));
        setAllOptionSelected(true);
        setSelectedItems(selectAll);
        updateData([]);
      }
    } else {
      const isSelected = selectedItems?.some(
        (item) => item.id === selectedItem.id
      );

      if (isSelected) {
        // Deselect the specific item
        const updatedItems = selectedItems.filter(
          (item) => item.id !== selectedItem.id
        );
        setSelectedItems(updatedItems);
        setAllOptionSelected(false);
        updateData(updatedItems);
      } else {
        // Select the specific item
        const updatedItems = [...selectedItems, selectedItem];
        const allSelected = updatedItems.length === options.length;
        setSelectedItems(updatedItems);
        setAllOptionSelected(allSelected);
        updateData(updatedItems);
      }
    }
  };

  const updateData = (updatedItems) => {
    const data = {
      ...userTypeData,
      cat_id: updatedItems.map((item) => item.id).toString(),
      merchant_id: merchantData?.data?.merchant_id,
      start_date: dateRange?.start_date,
      end_date: dateRange?.end_date,
    };
    // if (data) {
    //   fetchData(data);
    // }
  };

  return {
    selectedItems,
    allOptionSelected,
    handleOptionClick,
  };
};

export default useMultiSelect;
